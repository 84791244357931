import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import videoSrc from '../assets/construction-720.mp4'
import conLogo from '../images/construction/logo.png'
import { StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
const ConstructionPage = props => (
    <Layout containerClass="construction">
        <SEO title="Construction" />
        <Hero title="construction" video={videoSrc}  mobile={props.data.header.childImageSharp.fluid}/>
        <section id="main" className="main section">
            <div className="intro white first">
                <div className="uk-container uk-container-center">
                    <div className="uk-flex uk-flex-wrap uk-flex-center uk-flex-middle">
                        <div className="uk-width-1-1 uk-width-3-4@l">
                            
                            <p className="h1">
                                Building from the ground up to extensions and more, Allcor Fabrication can help you with you residential construction needs.                                  
                            </p>
                        </div>
                        <div className="uk-width-1-1 uk-width-1-4@l">
                            <img src={conLogo}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery">
                <div className="uk-flex uk-flex-wrap uk-flex-middle ">
                    <div className="uk-width-1-2@l uk-cover-container">
                        <Img fluid={props.data.one.childImageSharp.fluid} />
                    </div>
                    <div className="uk-width-1-2@l uk-cover-container">
                        <Img fluid={props.data.two.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
            <div className="white intro">
                <div className="uk-container uk-container-center">
                    <div className="uk-flex uk-flex-center uk-flex-middle">
                        <div className="box black uk-width-4-5@l uk-width-1-1">
                            <p className="text-white">
                                We are licenced building practitioners and have a vast knowledge of construction in the residential building market. 
                                Our services include new builds, renovations, new bathrooms , new kitchens and decks.
                            </p>
                            <p className="text-white">
                                From large construction projects to small Allcor Fabrication has it covered. 
                                We combine our building and engineering background to offer a fresh and complete approach to any construction project. 
                                We have a wide range of resources and expertise to offer a stress free and on budget project.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        
                  
            <div className="gallery">
                <div className="uk-flex uk-flex-wrap uk-flex-middle ">          
                    <div className="uk-width-1-3@l uk-cover-container">
                        <Img fluid={props.data.three.childImageSharp.fluid} className=""  />
                    </div>
                    <div className="uk-width-1-3@l uk-cover-container">
                        <Img fluid={props.data.four.childImageSharp.fluid} className=""  />
                    </div>
                    <div className="uk-width-1-3@l uk-cover-container">
                        <Img fluid={props.data.five.childImageSharp.fluid} className="portrait-landscape"  />
                    </div>
                </div>
            </div>        
        </section>
    </Layout>
)

export default ConstructionPage


export const query = graphql`
  query constructionQuery {
    header:file(relativePath: { eq: "construction/1.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    one:file(relativePath: { eq: "construction/1.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    two:file(relativePath: { eq: "construction/2.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 600) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    three:file(relativePath: { eq: "construction/3.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    four:file(relativePath: { eq: "construction/4.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    five:file(relativePath: { eq: "construction/5.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }        
  }
`;